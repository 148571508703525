import Vue from "vue";

export async function index() {
    const response = await Vue.prototype.$http.get("/config_attachments/upload");

    return response.data;
}

export async function storeMaxAttachmentsUploadSize(payload) {
    const response = await Vue.prototype.$http.post("/config_attachments/upload/max_attachments_upload_size", payload);

    return response.data;
}