<template>
    <div>
        <v-row>
            <v-col :style="`max-width: ${maxWidth}px`" fluid>
                <slot />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        maxWidth: {
            type: Number,
            default: 600
        }
    }
}
</script>