<template>
  <div>
    <v-card flat color="#f1f1f1" class="mb-4">
      <v-list-item v-if="title">
        <v-list-item-content>
          <v-list-item-title class="title">
            <slot name="title">
              {{ title }}
            </slot>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-card-text>
        <slot name="description">
          {{ description }}
        </slot>
      </v-card-text>
    </v-card>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
  },
};
</script>
