<template>
    <ConfigPage>
        <ConfigItem 
            :title="__('Tamanho máximo', 'settings')" 
            :description="__('Qualquer arquivo com tamanho maior do definido nessa tela será bloqueado', 'settings')"
        >
            <FileSizeForm :title="__('Limite de upload gestão documental', 'settings')" :loading="loading" ref="uploadLimit" @reset="getData" @submit="submit" />
        </ConfigItem>
    </ConfigPage>
</template>

<script>
import ConfigPage from "@/layouts/configs/ConfigPage";
import ConfigItem from "@/layouts/configs/ConfigItem";
import FileSizeForm from "@/components/forms/configs/FileSizeForm";
import { index, storeMaxAttachmentsUploadSize } from "@/services/configs/upload";
import i18n from '@/mixins/i18n';

export default {
    mixins: [i18n],
    components: { ConfigPage, ConfigItem, FileSizeForm },

    data() {
        return {
            loading: false
        }
    },

    methods: {
        async getData() {
            this.loading = true;
            const response = await index();

            this.$refs.uploadLimit.fill(response.max_attachments_upload_size);
            this.loading = false;
        },
        async submit(value) {
            this.loading = true;
            try {
                await storeMaxAttachmentsUploadSize({
                    max_attachments_upload_size: value
                });
            } catch(error) {
                if(error.response?.status === 422) {
                    this.$refs.uploadLimit.setErrors(error.response.data.errors);
                }
            } finally {
                this.loading = false;
            }
        }
    },

    created() {
        this.getData();
    }
}
</script>