<template>
    <v-card flat class="ma-4" :loading="loading" :disabled="loading">
        <ValidationObserver ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit">
                <v-card-title>
                    {{ title }}
                    <v-spacer></v-spacer>
                    <v-btn @click="getData()" icon>
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <ValidationProvider
                        name="max_attachments_upload_size"
                        rules="required|min_value:1|max_value:10000000"
                        v-slot="{ errors }"
                    >
                        <v-text-field :label="__('Tamanho máximo', 'settings')" outlined type="number" dense :value="value" @input="setValue" :error-messages="errors" single-line>
                            <template v-slot:append>
                                <span class="mt-1">
                                    KB
                                </span>
                            </template>
                        </v-text-field>
                    </ValidationProvider>

                    <v-chip-group
                        column
                        :value="value"
                        @change="setValue"
                        active-class="primary--text"
                    >
                        <v-chip v-for="sugestion in sugestions" :key="sugestion.value" :value="sugestion.value" small>
                            {{ sugestion.text }}
                        </v-chip>
                    </v-chip-group>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn type="submit" color="primary" :disabled="invalid" elevation="0">
                        {{ __('Salvar', 'settings') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </ValidationObserver>
    </v-card>
</template>

<script>
import i18n from '@/mixins/i18n';

export default {
    mixins: [i18n],
    props: {
        title: String,
        loading: {
            type: Boolean,
            default: false
        },
        sugestions: {
            type: Array,
            default: () => ([
                {
                    text: "1 MB",
                    value: 1000
                },
                {
                    text: "5 MB",
                    value: 5000
                },
                {
                    text: "10 MB",
                    value: 10000
                },
                {
                    text: "20 MB",
                    value: 20000
                },
                {
                    text: "30 MB",
                    value: 30000
                },
                {
                    text: "50 MB",
                    value: 50000
                },
                {
                    text: "100 MB",
                    value: 100000
                },
                {
                    text: "1 GB",
                    value: 1000000
                },
                {
                    text: "5 GB",
                    value: 5000000
                },
                {
                    text: "10 GB",
                    value: 10000000
                },
            ])
        }
    },

    data() {
        return {
            value: 1,
        };
    },

    methods: {
        async submit() {
            const validation = await this.$refs.form.validate();
            if(!validation) return;

            this.$emit("submit", this.value)
        },
        fill(value) {
            this.value = value;
            this.$refs.form.reset();
        },
        setErrors(errors) {
            this.$refs.form.setErrors(errors);
        },
        getData() {
            this.$emit("reset");
        },
        setValue(value) {
            this.value = value;
        }
    }
}
</script>